@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scrollbar-color: rgba(255, 255, 255, 0.031) rgba(255, 255, 255, 0);
  font-family: 'Poppins', Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
